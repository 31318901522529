export default function slackNotify(text: string) {
  const slackHook =
    "https://hooks.slack.com/services/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";

  const url = "https://gvnfgbfzy3.execute-api.us-west-2.amazonaws.com/Stage/px";
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      text,
      url: slackHook,
    }),
  })
    .then((response) => {
      console.log("Success:", response);
      sessionStorage.setItem("isLoggedIn", "true");
    })
    .catch((error) => {
      console.log("🚀 ~ error:", error);
    });
}
